
import { Link } from "react-router-dom";

const columnLabelStyling = "col-8 col-lg-6";
const columnStyling = "col-2 col-lg-3";

const Roster = (props) => {

  return (
    props.TeamDetails.qbDetails
      ?
      <div className="roster-container w-100 d-inline-block">
        <h2>
          <img src={props.TeamDetails.teamLogoHref} alt={props.TeamDetails.teamName}
            className="team-logo" />
          {props.TeamDetails.teamName}
        </h2>
        <div>Record: ({props.TeamDetails.record})</div>
        <div className="container w-100">
          <div className="row">
            <div className={columnLabelStyling}><h5>Quarterback</h5></div>
            <div className={columnStyling}></div>
            <div className={columnStyling}></div>
          </div>
          <div className="row">
            <div className={columnLabelStyling}>
              <Link to={`/quarterbacks/${props.TeamDetails.qbDetails.id}`} className="player-link">
                {props.TeamDetails.qbDetails.name}
              </Link>
            </div>
            <div className={columnStyling}>Prop</div>
            <div className={columnStyling}>Hits</div>
            </div>
          {
            props.TeamDetails.qbDetails.props.map(prop =>
            <div className="row prop-row">
              <div className={columnLabelStyling}>- {prop[0]}</div>
              <div className={columnStyling}>{prop[1]}</div>
              <div className={columnStyling + (eval(prop[2]) > .75 ? " text-over" : eval(prop[2]) < .34 ? " text-danger" : "")}>{prop[2]}</div>
            </div>
              )}
        </div>
        <br />
        {
          <div className="container w-100">
            <div className="row">
              <div className={columnLabelStyling}><h5>Running Backs</h5></div>
              <div className={columnStyling}></div>
              <div className={columnStyling}></div>
            </div>
            {
              props.TeamDetails.rbDetails.map(details => (<div>
                <div className="row">
                  <div className={columnLabelStyling}>
                    <Link to={`/runningbacks/${details.id}`} className="player-link">
                      {details.name}
                    </Link>
                  </div>
                  <div className={columnStyling}>Prop</div>
                  <div className={columnStyling}>Hits</div>
                </div>
                {details.props.map(prop =>
                  <div className="row prop-row">
                    <div className={columnLabelStyling}>- {prop[0]}</div>
                    <div className={columnStyling}>{prop[1]}</div>
                    <div className={columnStyling + (eval(prop[2]) > .75 ? " text-over" : eval(prop[2]) < .34 ? " text-danger" : "")}>{prop[2]}</div>
                  </div>
                )}
              </div>))
            }
          </div>
        }
        <br />
        {
          <div className="container w-100">
            <div className="row">
              <div className={columnLabelStyling}><h5>Wide Receivers</h5></div>
              <div className={columnStyling}>Prop</div>
              <div className={columnStyling}>Hits</div>
            </div>
            {
              props.TeamDetails.wrDetails.map(details => (<div>
                <div className="row">
                  <div className={columnLabelStyling}>
                    <Link to={`/widerecievers/${details.id}`} className="player-link">
                      {details.name}
                    </Link>
                  </div>
                  <div className={columnStyling}></div>
                  <div className={columnStyling}></div>
                </div>
                {details.props.map(prop =>
                  <div className="row prop-row">
                    <div className={columnLabelStyling}>- {prop[0]}</div>
                    <div className={columnStyling}>{prop[1]}</div>
                    <div className={columnStyling + (eval(prop[2]) > .75 ? " text-over" : eval(prop[2]) < .34 ? " text-danger" : "")}>{prop[2]}</div>
                  </div>
                )}
              </div>))
            }
          </div>
        }
        <br />
        {
          <div className="container w-100">
            <div className="row">
              <div className={columnLabelStyling}><h5>Tight Ends</h5></div>
              <div className={columnStyling}>Prop</div>
              <div className={columnStyling}>Hits</div>
            </div>
            {
              props.TeamDetails.teDetails.map(details => (<div>
                <div className="row">
                  <div className={columnLabelStyling}>
                    <Link to={`/tightends/${details.id}`} className="player-link">
                      {details.name}
                    </Link>
                  </div>
                  <div className={columnStyling}></div>
                  <div className={columnStyling}></div>
                </div>
                {details.props.map(prop =>
                  <div className="row prop-row">
                    <div className={columnLabelStyling}>- {prop[0]}</div>
                    <div className={columnStyling}>{prop[1]}</div>
                    <div className={columnStyling + (eval(prop[2]) > .75 ? " text-over" : eval(prop[2]) < .34 ? " text-danger" : "")}>{prop[2]}</div>
                  </div>
                )}
              </div>))
            }
          </div>
        }
        {
          props.TeamDetails.teamStats.map(details => (
            <div>
              <br />
              <div className="position-header"><b>{details.name}</b></div>
              <table className="w-100">
                {
                  details.indStats.map(stat => (<tr>
                    <td>{stat[0]}:</td>
                    <td>{stat[1]}</td>
                  </tr>))
                }
              </table>
            </div>
          ))
        }
      </div>
      : ""
  );
};

export default Roster;
