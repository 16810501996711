import './App.css';
import QBs from './Components/qbs';
import WRs from './Components/wrs';
import RBs from './Components/runningBacks';
import TEs from './Components/tightEnds';
import GameDetails from './Components/gamedetails';
import Home from './Components/home';
import { BrowserRouter,Link, Routes, Route } from "react-router-dom";
import React from 'react';

// We give each route either a target `component`, or we can send functions in `render` or `children` 
// that return valid nodes. `children` always returns the given node whether there is a match or not.
const App = () => (
  <div>
    <BrowserRouter>
        <div className='d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start'>
          <img src={ require('./logo.png') } height={"90px"} />
          <ul className='nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 header-links'>
            <li className='nav-link px-1 link-dark'><Link to="/" className='header'>Home</Link>{' '}</li>
            <li className='nav-link px-1 link-dark'><Link to={{pathname: '/quarterbacks'}} className='header'>Quarterbacks</Link>{' '}</li>
            <li className='nav-link px-1 link-dark'><Link to={{pathname: '/widerecievers'}} className='header'>Wide Receivers</Link>{' '} </li>
            <li className='nav-link px-1 link-dark'><Link to={{pathname: '/tightends'}} className='header'>Tight Ends</Link>{' '}</li>
            <li className='nav-link px-1 link-dark'><Link to={{pathname: '/runningbacks'}} className='header'>Running Backs</Link>{' '}</li>  
          </ul>
        </div>

        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/quarterbacks/:qbId" element={<QBs/>} />
          <Route path="/quarterbacks" element={<QBs/>} />
          <Route path="/widerecievers" element={<WRs/>} />
          <Route path="/widerecievers/:wrId" element={<WRs/>} />
          <Route path="/runningbacks" element={<RBs/>} />
          <Route path="/runningbacks/:rbId" element={<RBs/>} />
          <Route path="/tightends" element={<TEs/>} />
          <Route path="/tightends/:teId" element={<TEs/>} />
          <Route path="/gamedetails/:gameId" element={<GameDetails/>} />
          <Route render={() => <h1>Page not found</h1>} />
        </Routes>
    </BrowserRouter>
  </div>
);

export default App;