import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {useNavigate} from "react-router-dom";
import { BarChart, Cell, Bar, XAxis, YAxis, CartesianGrid, ReferenceLine, Label, ResponsiveContainer  } from 'recharts';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

var moment = require('moment');

const Athletes = (props) => {
  const [data, setData] = useState({
    Athletes: [],
    AthleteStats: [],
    AthletePropChart: [],
    Props: [],
    SelectedProp: '',
    SelectedPropName: ''
  });

  useEffect(() => {
    fetch('/getAll'+props.Position.replace(/\s/g, ""))
      .then(response => response.json())
      .then(async response => {
        let sortedResponse = response.sort(function(a, b) {
          var nameA = a.name.toLowerCase();
          var nameB = b.name.toLowerCase();
        
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names are equal
        });
        const formatted = sortedResponse.map(x => ({ value: x.id, label: x.name + " | " + x.team }));
        setData({ ...data, ['Athletes']: formatted });

        if (props.AthleteId) {
          var defaultAth = formatted.filter(ath => ath.value == props.AthleteId)[0];
          setSelectedOption(defaultAth);
          await GetAthleteStats(defaultAth);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  async function GetAthleteStats(selectedOption) {
    setSelectedOption(selectedOption);
    
    const promises = {};
    promises["promise0"] = fetch('https://site.web.api.espn.com/apis/common/v3/sports/football/nfl/athletes/'+selectedOption.value+'/gamelog?region=us&lang=en&contentorigin=espn&season=2024');

    props.Categories.forEach((value, index) =>
      promises["promise"+(index+1)] = fetch('https://sportsbook-nash.draftkings.com/api/sportscontent/dkusva/v1/leagues/88808/categories/'+value[0]+'/subcategories/'+value[1]));

    const responses = await Promise.all(Object.values(promises));
    let propData = await Promise.all(responses.map(response => response.json()));
  
    let propResponses = propData.splice(1,propData.length-1);
    let propValues = [];
    propResponses.forEach((obj) => {
      let currentProp = obj.selections.filter(odds => odds.participants[0].name == selectedOption.label.split(" | ")[0]);
      propValues.push(currentProp.length > 0 ? currentProp[0].points : "N/A");
    });
    props.DefaultProps.forEach((obj) => {
      propValues.splice(obj[0], 0, obj[1]);
    });

    const playerStats = propData[0];
    let formattedStats = playerStats.seasonTypes 
      ? playerStats.seasonTypes[0].categories[0].events
          .map((obj) => {
            return {
              OPP: playerStats.events[obj.eventId].opponent.displayName,
              OpponentAbrv: playerStats.events[obj.eventId].opponent.abbreviation,
              Date: moment(playerStats.events[obj.eventId].gameDate).format('M/D/YYYY'),
              [props.Columns[0][1]]: obj.stats[props.Columns[0][2]],
              [props.Columns[1][1]]: obj.stats[props.Columns[1][2]],
              [props.Columns[2][1]]: obj.stats[props.Columns[2][2]],
              [props.Columns[3][1]]: obj.stats[props.Columns[3][2]],
              [props.Columns[4][1]]: obj.stats[props.Columns[4][2]],
            };
          })
          .sort((a, b) => new Date(a.Date) - new Date(b.Date))
      : [];

    const barChart = formattedStats
      .map(function (stat) { return { name: stat.OpponentAbrv, value: stat[props.Columns[0][1]] } });
    setData({
      ...data,
      ['AthleteStats']: formattedStats,
      ['AthletePropChart']: barChart,
      ['SelectedProp']: '0',
      ['SelectedPropName']: props.Columns[0][0],
      ['Props']: propValues
    });
      
  }

  const [selectedOption, setSelectedOption] = useState(null);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in history
  };

  const colorScale = (value) => {
    if(data.Props[data.SelectedProp] == "N/A" || data.Props[data.SelectedProp] == "") return '#41b0ce';

    if (value > data.Props[data.SelectedProp]) return '#7bfe7b'; // Green
    if (value < data.Props[data.SelectedProp]) return '#ff0000'; // Red
    return ''; // 
  };

  const showOverUnderArrow = (target, value) => {
    if(target == "N/A" || target == "") return '';

    if (value >= target) return 'bi bi-arrow-up text-success';
    return 'bi bi-arrow-down text-danger';
  }; 

  const updateBarChart = (target, number, displayName) => {    
    const qBPassChart = data.AthleteStats
      .map(function(stat){ return { name: stat.OpponentAbrv, value: stat[target] }});
    setData({ ...data, ['AthletePropChart']: qBPassChart, ['SelectedProp']: number, ['SelectedPropName']: displayName });
  };

  return (
    <div className="body-content">
      {props.AthleteId ? <button onClick={handleGoBack} className='previous'>&laquo; Back to Game Details</button> : ""}
      <h1>{props.Position}</h1>
      <div className='mb-3'>
        <label htmlFor="athlete">Choose a {props.Position}:</label><br />
        <Select
          value={selectedOption}
          onChange={GetAthleteStats}
          options={data.Athletes}
        />
      </div>
      {data.AthleteStats.length > 0
        ? <div className='mb-3'>
          <div className='panel player-details table-responsive'>
          <table className="table" style={{"minWidth": "700px"}}>
            <tr className='table-footer'>
              <th scope='col'>Prop Targets</th>
              <th scope='col'></th>
              {data.Props.map(col => (<th scope='col'>{col}</th>))}
            </tr>
            <tr>
              <th scope='col'>Date</th>
              <th scope='col'>Opponent</th>
              {props.Columns.filter(obj => obj[0]).map(column => (<th  scope='col'>{column[0]}</th>))}
            </tr>
            {data.AthleteStats.map(item => (<tr className=''>
              <td scope='row'>{item.Date}</td>
              <td>{item.OPP}</td>              
              {props.Columns.filter(obj => obj[0]).map((column, indx) => (
                <td>{item[column[1]]} <span className={showOverUnderArrow(data.Props[indx], item[column[1]])}></span></td>
              ))}
            </tr>))}
            <tr className="table-footer table-group-divider">
              <th scope='row'>Total:</th>
              <td></td>
              {props.Columns.filter(obj => obj[0]).map(column => (
                <td>{data.AthleteStats.reduce((acc, value) => acc + parseInt(value[column[1]] ?? 0), 0)}</td>
              ))}
            </tr>
            <tr className="table-footer">
              <th scope='row'>Average:</th>
              <td></td>
              {props.Columns.filter(obj => obj[0]).map(column => (
                <td>{(data.AthleteStats.reduce((acc, value) => acc + parseInt(value[column[1]]), 0) / data.AthleteStats.length).toFixed(2)}</td>
              ))}
            </tr>
          </table>
          </div>
          <div className='mt-3 w-100 d-flex justify-content-center' style={{paddingLeft: '28px'}}>
            <ButtonGroup className="mb-2">
              {props.Columns.filter(obj => obj[0]).map((column, indx) => (
                <Button onClick={() => { updateBarChart(column[1], indx, column[0]) }}>{column[0]}</Button>
              ))}
            </ButtonGroup>
          </div>

          <div className='w-100 text-center'>
            <h5>{data.SelectedPropName}</h5>
          </div>

          <div className="">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart width={1100} height={400} data={data.AthletePropChart}              
                margin={{ top: 15, right: 45, bottom: 5 }}>
                <CartesianGrid />
                <XAxis dataKey="name" />
                <YAxis domain={[0, Math.max(...data.AthletePropChart.map(ev => parseInt(ev.value)))]} />
                <Bar dataKey="value" >
                  {
                    data.AthletePropChart.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colorScale(entry.value)} />
                    ))
                  }
                </Bar>
                <ReferenceLine  y={data.Props[data.SelectedProp]} stroke="white" strokeWidth={3} label={<Label value={data.Props[data.SelectedProp]} position="right"/> } />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        : selectedOption 
          ? "No data available."
          : ""
      }
    </div>
  );
};

export default Athletes;
