import React from 'react';
import { useParams } from "react-router-dom";
import Athletes from './athletes';

const TightEnds = () => {
  const categories = [ 
    ["1342", "14115"], 
    ["1342", "14114"]];
  const defaultProps = [[0, ""], [3, .5]];
  const columns = [
    // Column Display Name, Column Internal Name, Column Stat Index
    ["Targets", "Targets", 1],
    ["Receptions", "REC", 0],
    ["Receiving Yards", "REC_YDS", 2],
    ["Receiving TDs", "REC_TDS", 4],
    ["", "", 0]
  ];

  const { teId } = useParams();

  return (
    <Athletes
      AthleteId={teId}
      Position={"Tight Ends"}
      Categories={categories}
      DefaultProps={defaultProps}
      Columns={columns}/>
  );
};

export default TightEnds;
