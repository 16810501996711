import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Roster from './gamedetailsroster';
import Spinner from './spinner';
var moment = require('moment');


const DataFetchingComponent = () => {
    const [data, setData] = useState({
        HomeTeam: '',
        AwayTeam: '',
        StartTime: '',
        GamePreviewUrl: '',
        HomeTeamDetails: {},
        AwayTeamDetails: {}
    });
    let { gameId } = useParams();

    async function getPlayerStats(playerIds){        
        const promises = {};
        playerIds.forEach((value, index) =>
            promises["promise"+(index+1)] = fetch('https://site.web.api.espn.com/apis/common/v3/sports/football/nfl/athletes/'+value+'/gamelog?region=us&lang=en&contentorigin=espn&season=2024'));

        const responses = await Promise.all(Object.values(promises));
        const data = await Promise.all(responses.map(response => response.json()));

        return data;
    }

    function GetTeamRoster(homeTeamId, awayTeamId, homeTeam, awayTeam, startDateTime, gamePreviewUrl) {
        const propCategories = [
            ["1342", "14114"],
            ["1000", "9524"],
            ["1000", "9517"],
            ["1000", "9522"],
            ["1001", "9514"]
        ];

        Promise.all([
            fetch('https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/2024/teams/'+homeTeamId+'/depthcharts'),
            fetch('https://site.api.espn.com/apis/site/v2/sports/football/nfl/teams/'+homeTeamId+'?enable=roster,stats'),
            fetch('https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/2024/teams/'+awayTeamId+'/depthcharts'),
            fetch('https://site.api.espn.com/apis/site/v2/sports/football/nfl/teams/'+awayTeamId+'?enable=roster,stats'),
            fetch('https://site.web.api.espn.com/apis/common/v3/sports/football/nfl/statistics/byteam?region=us&lang=en&contentorigin=espn&sort=opponent.passing.netYardsPerGame%3Aasc&limit=32'),
            // Recieving Yards Betting Odds
            fetch('https://sportsbook-nash.draftkings.com/api/sportscontent/dkusva/v1/leagues/88808/categories/1342/subcategories/14114'),
            // Passing Yards Betting Odds
            fetch('https://sportsbook-nash.draftkings.com/api/sportscontent/dkusva/v1/leagues/88808/categories/1000/subcategories/9524'),
            fetch('https://sportsbook-nash.draftkings.com/api/sportscontent/dkusva/v1/leagues/88808/categories/1000/subcategories/9517'),
            fetch('https://sportsbook-nash.draftkings.com/api/sportscontent/dkusva/v1/leagues/88808/categories/1000/subcategories/9522'),
            // Rushing Yards Betting Odds
            fetch('https://sportsbook-nash.draftkings.com/api/sportscontent/dkusva/v1/leagues/88808/categories/1001/subcategories/9514')
        ])
            .then(([res1, res2, res3, res4, res5, res6, res7, res8, res9, res10]) => 
                Promise.all([res1.json(), res2.json(), res3.json(), res4.json(), res5.json(), res6.json(), res7.json(), res8.json(), res9.json(), res10.json()]))
            .then(async ([homeTeamDepthChart, homeTeamRoster, awayTeamDepthChart, awayTeamRoster, defStats, 
                recYardsBettingOdds,
                passYardsBettingOdds, passAttemptBettingOdds, passCompBettingOdds,
                rushYardsBettingOdds]) => {
                
                const getTeamDetails = async (teamDepthChart, teamRoster) => {
                    if (teamDepthChart.items.length < 3) return {};

                    const teamPositions = teamDepthChart.items[2].positions;
                    let qbIds = [];
                    let rbIds = [];
                    let wrIds = [];
                    let teIds = [];
                    teamPositions.qb.athletes.forEach(qb => qbIds[qb.athlete.$ref.split('athletes/')[1].split("?")[0]] = qb.rank);
                    teamPositions.rb.athletes.forEach(rb => rbIds[rb.athlete.$ref.split('athletes/')[1].split("?")[0]] = rb.rank);
                    teamPositions.wr.athletes.forEach(wr => wrIds[wr.athlete.$ref.split('athletes/')[1].split("?")[0]] = wr.rank);
                    teamPositions.te.athletes.forEach(te => teIds[te.athlete.$ref.split('athletes/')[1].split("?")[0]] = te.rank);

                    const offensiveAthletes = teamRoster.team.athletes;
                    const playerFilter = (ath, ids) => {
                        return ids[ath.id] && (ath.injuries.length == 0 || ['Probable', 'Questionable'].indexOf(ath.injuries[0].status) != -1)
                    };
                    const qbInfo = offensiveAthletes.filter(ath => playerFilter(ath, qbIds))
                        .sort(function (a, b) { return qbIds[a.id] - qbIds[b.id]; })[0];
                    const wrInfo = offensiveAthletes.filter(ath => playerFilter(ath, wrIds))
                        .sort(function (a, b) { return wrIds[a.id] - wrIds[b.id]; }).slice(0, 3);
                    const teInfo = offensiveAthletes.filter(ath => playerFilter(ath, teIds))
                        .sort(function (a, b) { return teIds[a.id] - teIds[b.id]; }).slice(0, 2);
                    const rbInfo = offensiveAthletes.filter(ath => playerFilter(ath, rbIds))
                        .sort(function (a, b) { return rbIds[a.id] - rbIds[b.id]; }).slice(0, 2);

                    let allAthleteIds = [];
                    allAthleteIds.push(qbInfo.id);
                    wrInfo.forEach(ath => allAthleteIds.push(ath.id));
                    teInfo.forEach(ath => allAthleteIds.push(ath.id));
                    rbInfo.forEach(ath => allAthleteIds.push(ath.id));
                    const playerStats = await getPlayerStats(allAthleteIds);

                    let allAthleteStats = [];
                    allAthleteIds.forEach((ath, indx) => allAthleteStats[ath] = playerStats[indx]);

                    function getOrdinal(n) {
                        const ord = ["th", "st", "nd", "rd"];
                        const s = n % 100;
                        return n + (ord[(s - 20) % 10] || ord[s] || ord[0]);
                    }
                    const statsForTeam = defStats.teams.filter(team => team.team.id == teamRoster.team.id)[0];
                    const passingOffStats = statsForTeam.categories[2];
                    const passingDefStats = statsForTeam.categories[3];
                    const runningDefStats = statsForTeam.categories[5];
                    const pointsAllowedPerGame = passingDefStats.totals[5] + " (" + getOrdinal(passingDefStats.ranks[5]) + ")";
                    const avgPassingYardsAllowed = passingDefStats.totals[3] + " (" + getOrdinal((passingDefStats.ranks[3] - 33) * -1) + ")";
                    const avgRunningYardsAllowed = runningDefStats.totals[1] + " (" + getOrdinal(runningDefStats.ranks[1]) + ")";
                    const pointsScoredPerGame = passingOffStats.totals[5] + " (" + getOrdinal(passingOffStats.ranks[5]) + ")";
                    const teamStats = [
                        {
                            name: "Defensive Stats per Game (Rank)",
                            indStats: [
                                ["Points Allowed", pointsAllowedPerGame],
                                ["Passing Yards Allowed", avgPassingYardsAllowed],
                                ["Rushing Yards Allowed", avgRunningYardsAllowed]]
                        },
                        {
                            name: "Offensive Stats per Game (Rank)",
                            indStats: [
                                ["Points Scored", pointsScoredPerGame]]
                        }
                    ];

                    const qbYardOdds = passYardsBettingOdds.selections.filter(odds => odds.participants[0].name == qbInfo.displayName);
                    const qbAttemptOdds = passAttemptBettingOdds.selections.filter(odds => odds.participants[0].name == qbInfo.displayName);
                    const qbCompOdds = passCompBettingOdds.selections.filter(odds => odds.participants[0].name == qbInfo.displayName);
                    let qbProps = [];
                    const qbEvents = allAthleteStats[qbInfo.id].seasonTypes
                        ? allAthleteStats[qbInfo.id].seasonTypes[0].categories[0].events
                        : null;
                    qbProps.push(["Pass Attempts", qbAttemptOdds.length > 0 ? qbAttemptOdds[0].points : "N/A",
                        qbAttemptOdds.length > 0 && qbEvents
                            ? qbEvents.filter(e => e.stats[1] >= qbAttemptOdds[0].points).length + "/" + qbEvents.length
                            : ""]);
                    qbProps.push(["Pass Completions", qbCompOdds.length > 0 ? qbCompOdds[0].points : "N/A",
                        qbCompOdds.length > 0 && qbEvents
                            ? qbEvents.filter(e => e.stats[0] >= qbCompOdds[0].points).length + "/" + qbEvents.length
                            : ""]);
                    qbProps.push(["Pass Yards O/U", qbYardOdds.length > 0 ? qbYardOdds[0].points : "N/A",
                        qbYardOdds.length > 0 && qbEvents
                            ? qbEvents.filter(e => e.stats[2] >= qbYardOdds[0].points).length + "/" + qbEvents.length
                            : ""]);
                    qbProps.push(["Pass TDs O/U", 1.5,
                        qbEvents && qbEvents.length > 0
                            ? qbEvents.filter(e => e.stats[5] >= 1.5).length + "/" + qbEvents.length
                            : ""]);
                    qbProps.push(["Ints O/U", .5,
                        qbEvents && qbEvents.length > 0
                            ? qbEvents.filter(e => e.stats[6] >= .5).length + "/" + qbEvents.length
                            : ""]);
                    const qbDetails = {
                        name: qbInfo.displayName,
                        id: qbInfo.id,
                        props: qbProps
                    };

                    var rbNames = rbInfo.map(rb => rb.displayName);
                    const rbRecOdds = recYardsBettingOdds.selections.filter(odds => rbNames.indexOf(odds.participants[0].name) != -1);
                    const rbRushOdds = rushYardsBettingOdds.selections.filter(odds => rbNames.indexOf(odds.participants[0].name) != -1);
                    const rbDetails = rbInfo.map(function (rb) {
                        let recOdds = rbRecOdds.filter(odds => odds.participants[0].name == rb.displayName);
                        let rushOdds = rbRushOdds.filter(odds => odds.participants[0].name == rb.displayName);
                        const rbEvents = allAthleteStats[rb.id].seasonTypes
                            ? allAthleteStats[rb.id].seasonTypes[0].categories[0].events
                            : null;

                        let rbProps = [];
                        rbProps.push(["Rush Yards O/U", rushOdds.length > 0 ? rushOdds[0].points : "N/A",
                            rushOdds.length > 0 && rbEvents
                                ? rbEvents.filter(e => e.stats[1] >= rushOdds[0].points).length + "/" + rbEvents.length
                                : ""]);
                        rbProps.push(["Rec Yards O/U", recOdds.length > 0 ? recOdds[0].points : "N/A",
                            recOdds.length > 0 && rbEvents
                                ? rbEvents.filter(e => e.stats[7] >= recOdds[0].points).length + "/" + rbEvents.length
                                : ""]);
                        rbProps.push(["Anytime TD", "",
                            rbEvents
                                ? rbEvents.filter(e => e.stats[3] + e.stats[9] > 0).length + "/" + rbEvents.length
                                : ""]);
                        return {
                            name: rb.displayName,
                            id: rb.id,
                            props: rbProps
                        };
                    });

                    var wrNames = wrInfo.map(wr => wr.displayName);
                    const wrRecOdds = recYardsBettingOdds.selections.filter(odds => wrNames.indexOf(odds.participants[0].name) != -1);
                    const wrDetails = wrInfo.map(function (wr) {
                        let wrOddForPlayer = wrRecOdds.filter(odds => odds.participants[0].name == wr.displayName);
                        const wrEvents = allAthleteStats[wr.id].seasonTypes[0].categories[0].events;
                        let wrProps = [];
                        wrProps.push(["Rec Yards O/U", wrOddForPlayer.length > 0 ? wrOddForPlayer[0].points : "N/A",
                            wrOddForPlayer.length > 0
                                ? wrEvents.filter(e => e.stats[2] >= wrOddForPlayer[0].points).length + "/" + wrEvents.length
                                : ""]);
                        wrProps.push(["Anytime TD", "",
                            wrEvents
                                ? wrEvents.filter(e => e.stats[4] + e.stats[10] > 0).length + "/" + wrEvents.length
                                : ""]);
                        return {
                            name: wr.displayName,
                            id: wr.id,
                            props: wrProps
                        };
                    });

                    var teNames = teInfo.map(te => te.displayName);
                    const teRecOdds = recYardsBettingOdds.selections.filter(odds => teNames.indexOf(odds.participants[0].name) != -1);
                    const teDetails = teInfo.map(function (te) {
                        let teOddForPlayer = teRecOdds.filter(odds => odds.participants[0].name == te.displayName);
                        const teEvents = allAthleteStats[te.id].seasonTypes[0].categories[0].events;
                        let teProps = [];
                        teProps.push(["Rec Yards O/U", teOddForPlayer.length > 0 ? teOddForPlayer[0].points : "N/A",
                            teOddForPlayer.length > 0
                                ? teEvents.filter(e => e.stats[2] >= teOddForPlayer[0].points).length + "/" + teEvents.length
                                : ""]);
                        teProps.push(["Anytime TD", "",
                            teEvents
                                ? teEvents.filter(e => e.stats[4] + e.stats[10] > 0).length + "/" + teEvents.length
                                : ""]);
                        return {
                            name: te.displayName,
                            id: te.id,
                            props: teProps
                        };
                    });

                    let teamDetails = {
                        teamName: teamRoster.team.displayName,
                        teamLogoHref: teamRoster.team.logos[1].href,
                        record: teamRoster.team.record.items[0].summary,
                        qbDetails: qbDetails,
                        wrDetails: wrDetails,
                        teDetails: teDetails,
                        rbDetails: rbDetails,
                        teamStats: teamStats
                    };

                    return teamDetails;
                };

                setData({
                    ...data,
                    ['HomeTeam']: homeTeam,
                    ['AwayTeam']: awayTeam,
                    ['StartTime']: startDateTime,
                    ['GamePreviewUrl']: gamePreviewUrl,
                    ['HomeTeamDetails']: await getTeamDetails(homeTeamDepthChart, homeTeamRoster),
                    ['AwayTeamDetails']: await getTeamDetails(awayTeamDepthChart, awayTeamRoster)
                });

            })
            .catch(error => console.error('Error fetching data:', error));
    }

    useEffect(() => {
        fetch('/getGameById/' + gameId)
            .then(response => response.json())
            .then(gameDetails => {
                const homeTeam = gameDetails.Home_Team;
                const awayTeam = gameDetails.Away_Team;
                const gamePreviewUrl = gameDetails.GamePreviewUrl;
                const startDateTime = gameDetails.StartDateTime;
                GetTeamRoster(gameDetails.Home_Team_Id, gameDetails.Away_Team_Id, homeTeam, awayTeam, startDateTime, gamePreviewUrl);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <div className="body-content">
            {!data.AwayTeam && <Spinner />}
            {data.AwayTeam && (
                <div className="game-details">
                    {
                        data.GamePreviewUrl ?
                            <div className='game-preview-video'><iframe
                                width="660"
                                height="385"
                                src={data.GamePreviewUrl}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin"
                                allowfullscreen></iframe></div>
                            : ""
                    }
                    <div className='game-detail-start-time'>Start Time: {moment(data.StartTime).format('MM/DD/YYYY @ h:mm A')}</div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-6 mb-4'>
                                <Roster
                                    TeamDetails={data.AwayTeamDetails} />
                            </div>
                            <div className='col-sm-12 col-lg-6'>
                                <Roster
                                    TeamDetails={data.HomeTeamDetails} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DataFetchingComponent;
