import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Athletes from './athletes';

const Quarterbacks = () => {
  const categories = [["1000", "9517"], ["1000", "9522"], ["1000", "9524"]];
  const defaultProps = [[3, 1.5], [4, .5]];
  const columns = [
    // Column Display Name, Column Internal Name, Column Stat Index
    ["Pass Attempts", "PASS_ATMPTS", 1],
    ["Completions", "COMP", 0],
    ["Pass Yards", "PASS_YDS", 2],
    ["Pass TDs", "PASS_TDS", 5],
    ["Ints", "INTS", 6]
  ];

  const { qbId } = useParams();

  return (
    <Athletes
      AthleteId={qbId}
      Position={"Quarterbacks"}
      Categories={categories}
      DefaultProps={defaultProps}
      Columns={columns}/>
  );
};

export default Quarterbacks;
