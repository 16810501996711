import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
var moment = require('moment');


const Home = () => {
  const [data, setData] = useState({
    Games: []
  });

  let navigate = useNavigate(); 
  const routeChange = (id) =>{ 
    let path = `gamedetails/` + id; 
    navigate(path);
  }

  useEffect(() => {
    fetch('/getUpcomingGames')
      .then(response => response.json())
      .then(response => {
        setData({...data, ['Games']: response});
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  function IsToday(date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }  

  function FormatDate(date) {
    return moment(date).format('MM/D');
  }  

  
  return (
    <div className="body-content">
      <div className='panel'>
        <h1>Upcoming Games</h1>
        <div className="">
          <div className='row'>
            <div className='col-lg-3 col-2'>Date</div>
            <div className='col-lg-7 col-7'>Matchup</div>
            <div className='col-lg-2 col-3'></div>
          </div>        
          {
            data.Games.map(item => (<div className={IsToday(new Date(item.StartDateTime)) ? 'todays-game row pb-2' : 'row pb-2'}>
              <div className='col-lg-3 col-2'>{IsToday(new Date(item.StartDateTime)) 
                ? moment(item.StartDateTime).format('h:mm A') 
                : FormatDate(item.StartDateTime)}</div>
              <div className='col-lg-7 col-7'>{item.Away_Team.split(" ")[item.Away_Team.split(" ").length-1]} @ {item.Home_Team.split(" ")[item.Home_Team.split(" ").length-1]}</div>
              <div className='col-lg-2 col-3 text-center'><button onClick={() => routeChange(item.Id)} className='btn btn-light btn-sm view-details-btn'>Details</button></div>
              </div>))}
        </div>
      </div>
    </div>
  );
};

export default Home;
