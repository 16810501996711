import React from 'react';
import { useParams } from "react-router-dom";
import Athletes from './athletes';

const RunningBacks = () => {
  const categories = [["1001", "9518"], ["1001", "9514"]];
  const defaultProps = [[2, .5]];
  const columns = [
    // Column Display Name, Column Internal Name, Column Stat Index
    ["Rush Attempts", "RUSH_ATTMPTs", 0],
    ["Rush Yards", "RUSH_YDS", 1],
    ["Rush TDs", "RUSH_TDS", 3],
    ["", "", 0],
    ["", "", 0]
  ];

  const { rbId } = useParams();

  return (
    <Athletes
      AthleteId={rbId}
      Position={"Running Backs"}
      Categories={categories}
      DefaultProps={defaultProps}
      Columns={columns}/>
  );
};

export default RunningBacks;
